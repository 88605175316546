import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import {
  CustomCheckbox,
  CustomFileUpload,
  CustomMultipleFileUpload,
} from "../FormInputs";
import { getFileName, getFileNameDoc } from "../../../helpers/functions";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../Buttons";
import HttpsLockOpen from "@material-ui/icons/LockOpen";
import HttpsIcon from "@material-ui/icons/Https";
import PublicIcon from "@material-ui/icons/Public";
import { Tooltip, FormLabel, makeStyles } from "@material-ui/core";
import DeleteIcons from "@material-ui/icons/Delete";
import { CustomInputField } from "../FormInputs";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  listbox: {
    color: "#293461",
    fontFamily: "openSans-SemiBold, sans-serif",
    fontSize: "16px",
    backgroundColor: "#ffffff",
    border: "2px solid #8B008B",
  },
  noOptions: {
    fontFamily: "openSans-SemiBold, sans-serif",
    color: "#8294a5",
  },
  customTooltip: {
    backgroundColor: "#D9EAFD",
    color: "#293461",
    fontSize: "12px",
    borderRadius: "8px",
    fontFamily: "openSans-SemiBold, sans-serif",
  },
  customArrow: {
    color: "#D9EAFD",
  },
}));

export default function DocumentUploadModal({
  modal,
  setModal,
  handleUpload = () => {},
  url = "",
  setUrl = () => {},
  handleSuccess = () => {},
  file,
  fileRef,
  data,
  setData,
  shareDefault = false,
  shareDefaultMed = false,
  resolutionKind,
  hasDocumentType,
  menuItems,
}) {
  const [checked, setChecked] = useState();
  const [checkedme, setCheckedme] = useState("");
  const [type, setType] = useState("");
  const [me, setMe] = useState();
  const [input, setInput] = useState("");
  const classes = useStyles();

  useEffect(() => {
    if (modal) {
      setChecked("");
      setCheckedme("");
      setMe(shareDefault);
      setUrl("");
      setType("");
      setData([]);
      setInput("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, shareDefault, shareDefaultMed]);

  useEffect(() => {
    let visibilityType;
    if (!checked && !checkedme) visibilityType = "me";
    else if (checked && !checkedme) visibilityType = "others";
    else if (!checked && checkedme) visibilityType = "all";
    if (url) {
      url.map((urlPath) => {
        return data.push({
          url: {
            uploadType: type?.uploadType,
            category: type?.category,
            url: urlPath,
          },
          visibilityType: visibilityType,
        });
      });
      setType("");
      setUrl("");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const deleteDocs = (index, item) => {
    const filtered = data.filter((v, idx) => idx !== index);
    setData(filtered);
  };

  return (
    <Modal
      open={modal}
      onClose={() => setModal(false) || setData([])}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Add Document</Heading>
            <CloseModal
              onClick={() => setModal(false) || setData([])}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <AgentFormContainer {...{ resolutionKind }}>
            <CheckboxContainer style={{ marginTop: 6 }}>
              <CustomCheckbox
                checked={checked}
                onChange={(e) =>
                  setChecked(e?.target?.checked ? true : true) ||
                  setCheckedme(false) ||
                  setMe(false)
                }
              />
              <Tooltip
                title="Only JustAct team, i.e., Case Manager and the Neutral will be able to view the document. "
                placement="right"
                arrow
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <CheckBoxLabel for="secretDoc">{"Share with CM"}</CheckBoxLabel>
              </Tooltip>
            </CheckboxContainer>
            <CheckboxContainer style={{ marginTop: 6 }}>
              <CustomCheckbox
                checked={checkedme}
                onChange={(e) =>
                  setCheckedme(e?.target?.checked ? true : true) ||
                  setChecked(false) ||
                  setMe(false)
                }
              />
              <Tooltip
                title="All who have access to the case can view the document."
                placement="right"
                arrow
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <CheckBoxLabel for="secretDoc">
                  {"Share with all parties and CM"}
                </CheckBoxLabel>
              </Tooltip>
            </CheckboxContainer>
            <CheckboxContainer style={{ marginTop: 6 }}>
              <CustomCheckbox
                checked={me}
                onChange={(e) =>
                  setMe(e?.target?.checked ? true : true) ||
                  setChecked(false) ||
                  setCheckedme(false)
                }
              />
              <Tooltip
                title="Documents uploaded in private mode will not be available to any other party including JustAct team and the neutral."
                placement="right"
                arrow
                classes={{
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <CheckBoxLabel for="secretDoc">
                  {"Private Viewing Only"}
                </CheckBoxLabel>
              </Tooltip>
            </CheckboxContainer>
            <StyledForm />
            {hasDocumentType && (
              <div style={{ marginBottom: 10, width: "100%" }}>
                <Label>Document Type</Label>
                <Autocomplete
                  id="size-small-outlined"
                  size="small"
                  classes={{ noOptions: classes.noOptions }}
                  options={menuItems}
                  groupBy={(option) => option.category}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <CustomInputField {...params} variant="outlined" />
                    </div>
                  )}
                  disableClearable
                  renderGroup={(params) => (
                    <StyledContainer>
                      <div className="doc_row">
                        <div>
                          <FormLabel className="category">
                            {params.group}
                          </FormLabel>
                          <div className="name">{params.children}</div>
                        </div>
                      </div>
                    </StyledContainer>
                  )}
                  noOptionsText={
                    <div variant="body2" color="textSecondary">
                      No Document type found
                    </div>
                  }
                  onChange={(event, newValue) =>
                    setType({
                      category: newValue?.category,
                      uploadType: newValue?.name,
                    })
                  }
                />
              </div>
            )}
            <div style={{ marginBottom: 10 }}>
              <Label>Document</Label>
              <CustomFileUpload
                error={false}
                setFile={(file) => handleUpload(file)}
                style={{ marginTop: 6, width: "100%" }}
                file={{ name: getFileNameDoc(url) }}
                value={file}
                ref={fileRef}
                fileUrl={url}
                multiple="multiple"
                type="file"
                accept=".pdf, .mp3, .mp4, .docx, .mov, image/*"
                // accept=".pdf"
              />
            </div>
            {type === "Others" ? (
              <div>
                <Label>
                  {type}
                  {""}(Optional)
                </Label>
                <CustomInputField
                  variant="outlined"
                  style={{ marginTop: 6, width: "100%" }}
                  value={input}
                  onChange={(e) => setInput(e?.target?.value)}
                />
              </div>
            ) : null}
            <div>
              {data
                ? data.map((item, index) => (
                    <>
                      <div style={{ display: "flex" }}>
                        <CustomMultipleFileUpload
                          error={false}
                          style={{ marginTop: 6 }}
                          file={{ name: getFileName(item?.url?.url) }}
                          value={item?.url?.url}
                          ref={fileRef}
                          fileUrl={item?.url?.url}
                          setUrl={setUrl}
                          key={index}
                          type="file"
                          accept=".pdf, .mp3, .mp4, .docx, .mov, image/*"
                        />{" "}
                        <div style={{ display: "flex" }}>
                          <TypeIcon>
                            {item?.visibilityType === "me" ? (
                              <Tooltip
                                title={item?.url?.uploadType}
                                placement="top"
                              >
                                <HttpsIcon />
                              </Tooltip>
                            ) : item?.visibilityType === "others" ? (
                              <Tooltip
                                title={item?.url?.uploadType}
                                placement="top"
                              >
                                <HttpsLockOpen />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={item?.url?.uploadType}
                                placement="top"
                              >
                                <PublicIcon />
                              </Tooltip>
                            )}
                          </TypeIcon>{" "}
                          <DeleteIcon
                            onClick={() => {
                              deleteDocs(index, item);
                            }}
                            key={index}
                          >
                            <DeleteIcons style={{ color: COLORS.LOGOUT_RED }} />
                          </DeleteIcon>
                        </div>
                      </div>
                    </>
                  ))
                : ""}
            </div>
            <StyledFormElementsWrapper
              style={{ marginBottom: 13, fontSize: "18px" }}
            >
              <FormLabel
                style={{
                  fontFamily: theme.fonts.primaryFontSemiBold,
                  fontSize: 12,
                  color: COLORS.COLOR_DARK,
                }}
              >
                Note: For redressal of Securities Market disputes, conducted
                under the aegis of SEBI, it is recommended to share the
                documents only in Public Sharing mode
              </FormLabel>
            </StyledFormElementsWrapper>
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                style={{ width: "45%" }}
                onClick={(e) => {
                  e.preventDefault();
                  setModal(false);
                  setUrl("");
                  setData([]);
                  setInput("");
                }}
              >
                Cancel
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                //   disabled={!url || (shareDefault && !type)}
                disabled={!data?.length}
                style={{ width: "45%" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleSuccess(data);
                }}
              >
                Upload Document
              </PrimaryCTAButton>
            </ButtonContainer>
          </AgentFormContainer>
        </Container>
      </DrawerContainer>
    </Modal>
  );
}

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  backface-visibility: hidden;
  max-height: ${({ resolutionKind }) =>
    resolutionKind === "arbitration" ? "500px" : "436px"};
  overflow: auto;
  z-index: -1px;
  overflow-x: hidden;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.7;
  // text-transform: uppercase;
  color: ${COLORS.INPUT_LABEL};
`;

const CheckBoxLabel = styled.label`
  font-size: 16px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
`;

const DeleteIcon = styled.div`
  margin: 20px 5px 0px;
  cursor: pointer;
`;

const TypeIcon = styled.div`
  margin: 20px 5px 0px;
  cursor: pointer;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledForm = styled.div`
  margin-bottom: 10px;
`;

const StyledContainer = styled.div`
  & .doc_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 0px;
    & .category {
      font-size: 16px;
      flex: 1;
      margin-left: 18px;
      color: ${COLORS.INPUT_LABEL};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
    & .name {
      font-size: 16px;
      font-weight: 600;
      flex: 1;
      margin-left: 15px;
      color: ${COLORS.COLOR_DARK};
      font-family: ${theme.fonts.primaryFontSemiBold};
    }
  }
`;

const StyledFormElementsWrapper = styled.div`
  margin-bottom: 28px;
  max-width: 436px;
  & .link {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }
  & .template {
    margin-top: 0px;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
  }
  & .mediator-type {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: column !important;
      & .MuiFormControlLabel-root {
        padding: 10px 0px;
      }
    }
  }
  @media ${theme?.breakpoints?.sm_down} {
    & .template {
      margin-top: 15px;
    }
  }
`;
